<!-- 时效设置弹窗 -->
<template>
  <el-dialog
      width="60%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'水印配置 (拖拽及保存)':'水印配置 (拖拽及保存)'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="120px">

      <el-row :gutter="15">
        <el-col :span="12">
          <div style="display: flex;align-items: center;">
            <div style="display: flex;align-items: center; margin-right: 20px;">
              <span>选择字体颜色：</span>
              <input type="color" v-model="list.color" style="border: none;">
            </div>
            <div>
              <span>字体加粗：</span>
              <span style="cursor: pointer; display: inline-block;width: 20px;text-align: center;" :style="{backgroundColor:bgcolor}" @click="jiacu">B</span>
            </div>
          </div>
          <div class="list">
            <!--<div v-for="(item,index) in list" :key="index" class="list_active">-->
            <!--  <span style="font-size: 14px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>-->
            <!--  <i class="el-icon-close" style="color: #ff9b05; font-weight: bold;margin-left: 15px;" @click="del(item,index)"></i>-->
            <!--</div>-->

            <div v-for="(item,index) in list.variable" :key="index">
              <div style="padding: 10px;">
                <div>第{{index}}行</div>
                <draggable v-model="list.variable[index]" group="people" @change="log" @end="onEnd">
                  <template>
                    <span style="color: #ff9b05;" v-for="(it,idx) in list.variable[index]" :key="idx" class="list_active">
                      {{it.label}}
                    </span>
                  </template>
                </draggable>
              </div>
            </div>

            <!--<div>第二行</div>-->
            <!--<div style="padding: 10px;">-->
            <!--  <draggable v-model="list1" group="people" @change="log">-->
            <!--    <template style="padding: 10px;">-->
            <!--      &lt;!&ndash;<div style="display: flex; padding: 10px;">&ndash;&gt;-->
            <!--      &lt;!&ndash;  &ndash;&gt;-->
            <!--      &lt;!&ndash;</div>&ndash;&gt;-->
            <!--      <div v-for="(item,index) in list1" :key="index" class="list_active">-->
            <!--        <span style="font-size: 14px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>-->
            <!--        <i class="el-icon-close" style="color: #ff9b05; font-weight: bold;margin-left: 15px;" @click="del(item,index)"></i>-->
            <!--      </div>-->
            <!--    </template>-->
            <!--  </draggable>-->
            <!--</div>-->

            <!--<div>第三行</div>-->
            <!--<div style="padding: 10px;">-->
            <!--  <draggable v-model="list2" group="people" @change="log">-->
            <!--    <template>-->
            <!--      &lt;!&ndash;<div style="display: flex;padding: 10px;">&ndash;&gt;-->
            <!--      &lt;!&ndash;  &ndash;&gt;-->
            <!--      &lt;!&ndash;</div>&ndash;&gt;-->
            <!--      <div v-for="(item,index) in list2" :key="index" class="list_active">-->
            <!--        <span style="font-size: 14px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>-->
            <!--        <i class="el-icon-close" style="color: #ff9b05; font-weight: bold;margin-left: 15px;" @click="del(item,index)"></i>-->
            <!--      </div>-->
            <!--    </template>-->
            <!--  </draggable>-->
            <!--</div>-->

            <!--<div>第四行</div>-->
            <!--<div style="padding: 10px;">-->
            <!--  <draggable v-model="list3" group="people" @change="log">-->
            <!--    <template>-->
            <!--      &lt;!&ndash;<div style="display: flex;padding: 10px;">&ndash;&gt;-->
            <!--      &lt;!&ndash;  &ndash;&gt;-->
            <!--      &lt;!&ndash;</div>&ndash;&gt;-->
            <!--      <div v-for="(item,index) in list3" :key="index" class="list_active">-->
            <!--        <span style="font-size: 14px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>-->
            <!--        <i class="el-icon-close" style="color: #ff9b05; font-weight: bold;margin-left: 15px;" @click="del(item,index)"></i>-->
            <!--      </div>-->
            <!--    </template>-->
            <!--  </draggable>-->
            <!--</div>-->

            <!--<div>第五行</div>-->
            <!--<div style="padding: 10px;">-->
            <!--  <draggable v-model="list4" group="people" @change="log">-->
            <!--    <template>-->
            <!--      &lt;!&ndash;<div style="display: flex;padding: 10px;">&ndash;&gt;-->
            <!--      &lt;!&ndash;  &ndash;&gt;-->
            <!--      &lt;!&ndash;</div>&ndash;&gt;-->
            <!--      <div v-for="(item,index) in list4" :key="index" class="list_active">-->
            <!--        <span style="font-size: 14px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>-->
            <!--        &lt;!&ndash;<i class="el-icon-close" style="color: #ff9b05; font-weight: bold;margin-left: 15px;" @click="del(item,index)"></i>&ndash;&gt;-->
            <!--      </div>-->
            <!--    </template>-->
            <!--  </draggable>-->
            <!--</div>-->

          </div>
        </el-col>
        <el-col :span="12">
          <div class="bg_img" :style="{ backgroundImage: `url(${img})` }">
            <div v-for="(item,index) in list1.variable" :key="index">
              <span  style="font-size: 14px; display: inline-block; margin: 10px;" v-for="(it,idx) in list1.variable[index]" :key="idx" :style="{color: list1.color,fontWeight:weigt}">
                {{it}}
              </span>
            </div>
            <!--<div>-->
            <!--  <span  v-for="(item,index) in list1" :key="index" style="font-size: 14px;display: inline-block; margin: 10px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>-->
            <!--</div>-->
            <!--<div>-->
            <!--  <span  v-for="(item,index) in list2" :key="index" style="font-size: 14px;display: inline-block; margin: 10px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>-->
            <!--</div>-->
            <!--<div>-->
            <!--  <span  v-for="(item,index) in list3" :key="index" style="font-size: 14px;display: inline-block; margin: 10px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>-->
            <!--</div>-->
            <!--<div>-->
            <!--  <span  v-for="(item,index) in list4" :key="index" style="font-size: 14px;display: inline-block; margin: 10px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>-->
            <!--</div>-->
          </div>
        </el-col>

      </el-row>

    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <!--<el-button-->
      <!--  style="margin-left: 20px;"-->
      <!--  type="primary"-->
      <!--  :loading="loading"-->
      <!--  @click="save">确定-->
      <!--</el-button>-->
    </div>

  </el-dialog>
</template>

<script>
import draggable from 'vuedraggable'
import {Addsave_watermark, get_watermark, get_watermark_info} from "@/api/yunli";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    id:Number
  },
  components: {
    draggable
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      img:require('../../../../../../assets/images/home/shilitu.png'),

      showCode:false,
      color:'#FF0707',
      weigt:'',
      bgcolor:'',


      list:{},
      list1:{},

      DataList:[],

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    this.getConfig();
    this.getConfig2();
  },
  methods: {
    // 获取配置
    getConfig(){
      get_watermark(this.id).then(res => {
        this.list = res.data;
        if(this.list.font_width == 'bold'){
          this.bgcolor = '#e9eaeb';
        }else {
          this.bgcolor = '';
        }
      })
    },

    // 获取右侧值
    getConfig2(){
      get_watermark_info(this.id).then(res => {
        this.list1 = res.data;
      })
    },

    /* 保存编辑 */
    save() {
      console.log(this.list.variable)
      // this.$refs['form'].validate((valid) => {
      //   if (valid) {
      //     let data = {
      //       channel_id: this.id,
      //       color: this.color,
      //       font_width: this.weigt,
      //       variable: this.list
      //     }
      //     Addsave_watermark(data).then(res => {
      //       if(res.code == 200){
      //         this.$message.success(res.msg)
      //         this.loading = true;
      //         this.updateVisible(false);
      //         this.$emit('done2');
      //         this.loading = false;
      //       }else {
      //         this.$message.error(res.msg)
      //       }
      //     }).catch(err => {
      //       this.$message.error(err.msg)
      //     })
      //   } else {
      //     return false;
      //   }
      // });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
      this.$emit('done2');
    },

    //删除
    del(item,index){
      console.log(item);
      // 执行一些需要的逻辑
      this.list.splice(index, 1)
    },

    //加粗
    jiacu(){
      this.showCode = !this.showCode;
      //改变样式
      if(this.showCode == true){
        this.bgcolor = '#e9eaeb';
        this.weigt = 'bold';
      }else {
        this.bgcolor = '';
        this.weigt = '';
      }
    },

    // add: function() {
    //   this.list.push({ name: "Juan" });
    // },
    // replace: function() {
    //   this.list = [{ name: "Edgard" }];
    // },
    // clone: function(el) {
    //   return {
    //     name: el.name + " cloned"
    //   };
    // },
    log: function(evt) {
      window.console.log(evt);
      // console.log('保存')
    },

    onEnd(e) {
      console.log(e); // 这里将会有调整前后的 index 及其他可能需要传递给接口的信息
      let data = {
        // channel_id: this.id,
        color: this.color,
        font_width: this.weigt,
        variable: this.list.variable
      }
      Addsave_watermark(this.id,data).then(res => {
        if(res.code == 200){
          this.$message.success(res.msg)
          this.loading = true;
          this.loading = false;
          this.getConfig2();
        }else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.$message.error(err.msg)
      })
    },


  }
}
</script>

<style scoped lang="scss">
.bg_img{
  height: 460px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 20px;
}
.list{
  .list_active{
    padding-left: 10px;
    padding-right: 10px;
    background: #FFF5E5;
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 10px;
    cursor: pointer;
    display: inline-block;
  }
}

.list_active1{
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  background: #FFF5E5;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 10px;
  cursor: pointer;
}
</style>
